import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

function initialize() {
  const firebaseConfig = {
    apiKey: 'AIzaSyByh-HbDoZjOxaTqwEbMVUBv0EKgbRmFJs',
    appId: '1:212612852716:web:0218ec9fdace5e8b5e6ec9',
    authDomain: 'workshop-stack-academy.firebaseapp.com',
    messagingSenderId: '212612852716',
    projectId: 'workshop-stack-academy',
    storageBucket: 'workshop-stack-academy.appspot.com',
  };

  const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(firebaseConfig);

  return { app };
}

const { app } = initialize();
const firestore = app.firestore;

export { app, firebase, firestore };
