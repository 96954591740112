import { useCallback, useState } from 'react';

export enum AsyncStatus {
  Error = 'error',
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
}

export interface AsyncOptions<T = unknown> {
  errorSnackbar?: boolean;
  onError?: (error: Error) => void;
  onSuccess?: (response: T) => void;
  successSnackbar?: boolean | string;
}

export function useAsync<T = unknown>(fn: Function, options?: AsyncOptions<T>) {
  const [error, setError] = useState<Error>();
  const [response, setResponse] = useState<T>();
  const [status, setStatus] = useState(AsyncStatus.Idle);

  const execute = useCallback(
    (...args) => {
      setStatus(AsyncStatus.Pending);
      setError(undefined);

      return fn(...args)
        .then((x: T) => {
          setResponse(x);
          setStatus(AsyncStatus.Success);

          if (options?.onSuccess) {
            options.onSuccess(x);
          }

          return x;
        })
        .catch((x: Error) => {
          setResponse(undefined);
          setError(x);

          if (options?.onError) {
            options.onError(x);
          }

          setStatus(AsyncStatus.Error);
        });
    },
    [fn, options]
  );

  return {
    error,
    execute,
    pending: status === AsyncStatus.Pending,
    response,
    status,
  };
}
