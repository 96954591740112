import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import gfm from 'remark-gfm';

const useStyles = makeStyles(({ spacing }) => ({
  table: {
    marginBottom: spacing(2),
  },
  sandbox: {
    width: '100%',
    height: '500px',
    border: '0',
    borderRadius: '4px',
    overflow: 'hidden',
  },
}));

function MdCode({ language, value }: any) {
  return (
    <Prism
      style={vscDarkPlus}
      language={language}
      children={value}
      showLineNumbers={true}
    />
  );
}

function MdHeading({ children, level }: any) {
  return (
    <Typography variant={`h${level}` as any} paragraph>
      {children}
    </Typography>
  );
}

function MdLink(props: any) {
  const classes = useStyles();

  if (props.href.startsWith('https://codesandbox.io/')) {
    const url = props.href.replace(
      'https://codesandbox.io/s/',
      'https://codesandbox.io/embed/'
    );

    return (
      <iframe
        src={url}
        className={classes.sandbox}
        title="codesandbox"
        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
      ></iframe>
    );
  }

  return <Link {...props}></Link>;
}

function MdListItem({ children }: any) {
  return (
    <li>
      <Typography component="span">{children}</Typography>
    </li>
  );
}

function MdParagraph({ children }: any) {
  return <Typography paragraph>{children}</Typography>;
}

function MdTable({ children }: any) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>{children}</Table>
    </TableContainer>
  );
}

function MdTableBody({ children }: any) {
  return <TableBody>{children}</TableBody>;
}

function MdTableCell({ children }: any) {
  return <TableCell>{children}</TableCell>;
}

function MdTableHead({ children }: any) {
  return <TableHead>{children}</TableHead>;
}

function MdTableRow({ children }: any) {
  return <TableRow>{children}</TableRow>;
}

export function Markdown({ children }: { children: string }) {
  const renderers = {
    heading: MdHeading,
    paragraph: MdParagraph,
    link: MdLink,
    code: MdCode,
    table: MdTable,
    tableBody: MdTableBody,
    tableCell: MdTableCell,
    tableHead: MdTableHead,
    tableRow: MdTableRow,
    listItem: MdListItem,
  };

  return (
    <ReactMarkdown plugins={[gfm]} renderers={renderers}>
      {children}
    </ReactMarkdown>
  );
}
