import React, { ReactNode, useState } from 'react';
import { Drawer } from './Drawer';
import { Main } from './Main';
import { TopAppBar } from './TopAppBar';

export function Container({ children }: { children: ReactNode | ReactNode[] }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <TopAppBar onMenuClick={() => setDrawerOpen(x => !x)} />
      <Drawer onClose={() => setDrawerOpen(false)} open={drawerOpen} />
      <Main>{children}</Main>
    </>
  );
}
