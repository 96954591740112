import React from 'react';
import { Markdown } from './Markdown';

export function Home() {
  const markdown = `
  # Workshop

  Welcome to the workshop of the \`stack.academy\`. Where you can create a simple workshop using plain markdown.

  ## Simple markdown usage

  ## header 1

  ## header 2

  ### header 3

  #### header 4

  ##### header 5

  ###### header 6

  paragraph 1 foo bar lorem

  paragraph 2 bar foo und soo weiter

  [let us do it](www.google.at)

  https://codesandbox.io/s/elegant-aryabhata-j2n94?fontsize=14&hidenavigation=1&theme=dark

  www.google.at

  lorem ipsum

  A table:

  | a | b |
  | - | - |
  | A | table |

  lorem ipsum sit dolorem

  - I
  - am
    - inner
    - list
  - a
  - list


  \`\`\`javascript
  const a = 4;

  console.log(a);
  \`\`\`

  \`\`\`bash
  function main() {
    readonly foo="bar"

    echo "\${foo}"
  }

  main "$@"
  \`\`\`
  `;

  return <Markdown>{markdown}</Markdown>;
}
