import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    alignItems: 'center',
    display: 'grid',
    gap: spacing(2),
    gridTemplateColumns: 'max-content 1fr',
    width: '100%',
  },
  wrapper: {
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
}));

export function TopAppBar({
  onMenuClick,
}: {
  onMenuClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  const [show, setShow] = useState(true);
  const trigger = useScrollTrigger();
  const classes = useStyles();

  useEffect(() => {
    setShow(!trigger);
  }, [trigger]);

  const transition = useTransition(show, null, {
    from: { transform: 'translate3d(0, -100%, 0)' },
    enter: { transform: 'translate3d(0, 0%, 0)' },
    leave: { transform: 'translate3d(0, -100%, 0)' },
  });

  return (
    <>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className={classes.wrapper} style={props} key={key}>
              <AppBar position="relative">
                <Toolbar>
                  <div className={classes.container}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={onMenuClick}
                    >
                      <Menu />
                    </IconButton>
                    <Typography variant="h6">Workshop</Typography>
                  </div>
                </Toolbar>
              </AppBar>
            </animated.div>
          )
      )}
    </>
  );
}
