import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { createContext, ReactNode, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase } from '../firebase';

export interface User {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoUrl: string | null;
  uid: string;
}

export const AuthContext = createContext<{
  error: Error | undefined;
  loading: boolean;
  user: User | null;
}>({
  error: undefined,
  loading: true,
  user: null,
});

export function useAuthContext() {
  return useContext(AuthContext);
}

export function WithAuthContext({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [data, loading, error] = useAuthState(firebase.auth());

  const user =
    !data?.uid || error || loading
      ? null
      : {
          displayName: data.displayName || null,
          email: data.email || null,
          phoneNumber: data.phoneNumber || null,
          photoUrl: data.photoUrl || null,
          uid: data.uid,
        };

  if (loading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <AuthContext.Provider value={{ error, loading, user }}>
      {children}
    </AuthContext.Provider>
  );
}
