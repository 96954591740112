import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { useAuthContext } from '../context/auth';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '100%',
    margin: '0 auto',
    maxWidth: breakpoints.values.xl,
    [breakpoints.down('sm')]: {
      gap: spacing(2),
      paddingBottom: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: `calc(${spacing(2)}px + 56px)`,
    },
    [breakpoints.up('sm')]: {
      gap: spacing(3),
      paddingBottom: spacing(3),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      paddingTop: `calc(${spacing(3)}px + 64px)`,
    },
  },
}));

export function Main({ children }: { children: ReactNode | ReactNode[] }) {
  const { error } = useAuthContext();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {error && (
        <Alert severity="error">{error.message || 'Someting went wrong'}</Alert>
      )}
      <main>{children}</main>
    </div>
  );
}
