import {
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp, Home } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../context/auth';
import { firebase } from '../firebase';
import { useAsync } from '../hooks/async';

const useStyles = makeStyles({
  container: {
    maxWidth: '80vw',
    width: '250px',
  },
});

export function Drawer({
  onClose,
  open,
}: {
  onClose?: () => void;
  open: boolean;
}) {
  const classes = useStyles();
  const { user } = useAuthContext();
  const { push } = useHistory();
  const signout = useAsync(async () => {
    await firebase.auth().signOut();
    push('/signin');
  });

  return (
    <MuiDrawer open={open} onClose={onClose}>
      <div className={classes.container} onClick={onClose}>
        {signout.error && (
          <Alert severity="error">{signout.error.message}</Alert>
        )}
        {user?.uid ? (
          <>
            <List component="nav">
              <ListItem button onClick={() => push('/')}>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </List>
            <Divider />
            <List component="nav">
              <ListItem button onClick={signout.execute}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItem>
            </List>
          </>
        ) : (
          <List component="nav">
            <ListItem button onClick={() => push('/signin')}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Sign in" />
            </ListItem>
          </List>
        )}
      </div>
    </MuiDrawer>
  );
}
