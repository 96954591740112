import {
  Backdrop,
  CircularProgress,
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import { blue, deepOrange } from '@material-ui/core/colors';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { WithAuthContext } from '../context/auth';
import { Container } from './Container';
import { Home } from './Home';

const Signin = lazy(() => import('./Signin'));

const theme = createMuiTheme({
  palette: { primary: deepOrange, secondary: blue },
});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <WithAuthContext>
          <Suspense
            fallback={
              <Backdrop open={true}>
                <CircularProgress color="primary" />
              </Backdrop>
            }
          >
            <BrowserRouter>
              <Container>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/signin">
                    <Signin />
                  </Route>
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Container>
            </BrowserRouter>
          </Suspense>
        </WithAuthContext>
      </ThemeProvider>
    </>
  );
}

export default App;
